import request from '../utils/request';
import { REST_SERVICE } from '../config';

// 进度查询
// export const processCheck = (params) =>
//   request({
//     url: REST_SERVICE.jdcx.processCheck,
//     method: 'get',
//     params
//   });

// // 不动产登记进度查询
export const getYwzt = (params) =>
  request({
    url: REST_SERVICE.jdcx.getYwzt,
    method: 'post',
    params
  });

// 5.10web和公众号不动产登记进度查询
// export const getYwzWeb = (params) =>
//   request({
//     url: REST_SERVICE.jdcx.getYwzWeb,
//     method: 'get',
//     params
//   });
