<template>
  <div class="content progress-query">
    <div v-if="!this.$hasConstruction('IEBDC:SY:XXCX:JDCX:SFZJ')">
      <div class="form-content">
        <div class="tip">
          <span>进度查询</span>
        </div>
        <el-form label-width="150px" :model="formData" :rules="rules" ref="form" @submit.native.prevent>
          <el-row type="flex" justify="center" class="jdcx-center">
            <label>进度查询</label>
            <div class="jdcx-item flex" v-for="(item, index) in progressList" :key="index">
              <div v-if="index !== 0" class="jd-jt">
                <img :src="activeProgressImg(item.isActive)" />
              </div>
              <div class="jd-img">
                <div class="img-status">
                  <img :src="getLogoUrl(item)" />
                </div>
                <span :class="item.isActive ? 'label-active' : ''">{{ item.label }}</span>
              </div>
            </div>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :span="12">
              <el-form-item prop="ywh">
                <el-input
                  v-model.trim="formData.ywh"
                  @keyup.enter.native="submit"
                  placeholder="请输入业务号"
                ></el-input>
              </el-form-item>
            </el-col>
            <!--            <el-col :span="2">-->
            <!--              <div class="examples-link flex-center">-->
            <!--                <el-link type="primary" :href="slUrl" target="_blank">示例</el-link>-->
            <!--              </div>-->
            <!--            </el-col>-->
            <el-col :span="6">
              <el-form-item label-width="20px">
                <el-button type="primary" @click="submit">查询</el-button>
              </el-form-item>
              <!--              <el-form-item label="手机号：" prop="lxdh">-->
              <!--                <el-input v-model="formData.lxdh" placeholder="请输入手机号"></el-input>-->
              <!--              </el-form-item>-->
            </el-col>
          </el-row>
          <!-- 示例截图 -->
          <el-collapse v-model="activeNames">
            <el-collapse-item title="填写示例" name="1">
              <el-row type="flex" justify="center" class="example-list">
                <img class="example-img" src="../../assets/images/wycx/example1.png" />
                <!-- <img class="example-img" src="../../assets/images/wycx/example2.png"/> -->
              </el-row>
              <el-row type="flex" justify="center" class="example-list">
                <img class="example-img" src="../../assets/images/wycx/example2.png" />
                <!-- <img class="example-img" src="../../assets/images/wycx/example1.png"/> -->
              </el-row>
            </el-collapse-item>
          </el-collapse>

          <!--          <el-row :gutter="20">-->
          <!--            <el-col :span="12">-->
          <!--              <el-form-item-->
          <!--                label="验证码："-->
          <!--                prop="authCode"-->
          <!--              >-->
          <!--                <phone-code-->
          <!--                  v-model="formData.authCode"-->
          <!--                  :phone="formData.lxdh"-->
          <!--                  :disabled="formData.lxdh.length ? false : true"-->
          <!--                ></phone-code>-->
          <!--              </el-form-item>-->
          <!--            </el-col>-->
          <!--          </el-row>-->
        </el-form>
      </div>
    </div>
    <!--  后台配置权限该功能是否在建  -->
    <no-data v-else :data="dataNo"></no-data>
  </div>
</template>

<script>
import hasPermission from '@/pages/iebdc/mixin/hasPermission';
import { validatePhone } from '@iebdc/utils/validate';
import { getYwzt } from '@/pages/iebdc/api/progress-query-service.js';
import { mapGetters } from 'vuex';
import hasConstruction from '@iebdc/mixin/hasConstruction';
import { localDataUser } from '@iebdc/utils/local-data.js';

export default {
  name: 'progressQuery',
  mixins: [hasPermission, hasConstruction],
  data() {
    this.validataYWH = (rules, value, callback) => {
      const reg = /^(WW-)?(\d{14})$/g; //两种：20201024000701不动产;WW-20201024000015互联网
      if (!value) {
        callback('请输入查询编号');
      } else {
        if (!reg.test(value)) {
          callback('请输入正确的查询编号');
        }
      }
      callback();
    };
    this.rules = {
      ywh: [{ required: true, message: '请输入业务号', trigger: 'change' }],
      lxdh: [{ required: true, validator: validatePhone, trigger: 'blur' }],
      authCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
    };
    return {
      activeNames: ['1'],
      dataNo: {
        src: require('@/pages/iebdc/assets/images/nodata@2x.png'),
        imgWidth: '242px',
        imgHeight: '194px',
        title: '敬请期待',
        description: '正在建设中...',
      },
      slUrl: `${window.CONFIG.unify_ip}/iebdc/iebdc/index.html?lb#!/home`,
      formData: {
        // authCode: '',
        // djjg: '',
        // lxdh: '',
        ywh: '',
        qydm: '',
        cxr: localDataUser.get().name, //查询人姓名
        qqjgmc: '', //请求机构名称
        // loginName: userInfo.loginName,
        loginName: JSON.parse(localStorage.getItem('IEBDC_USER_DATA')).username,
      },
      progressList: [
        {
          label: '网上申请中',
          logo: require('@/pages/iebdc/assets/images/sqz.png'),
          activeLogo: require('@/pages/iebdc/assets/images/sqz1.png'),
          isActive: false,
        },
        {
          label: '网上审核中',
          logo: require('@/pages/iebdc/assets/images/wssqz.png'),
          activeLogo: require('@/pages/iebdc/assets/images/wssqz1.png'),
          isActive: false,
        },
        {
          label: '网上审核通过',
          logo: require('@/pages/iebdc/assets/images/shtg.png'),
          activeLogo: require('@/pages/iebdc/assets/images/shtg1.png'),
          isActive: false,
        },
        {
          label: '窗口受理',
          logo: require('../../assets/images/sl.png'),
          activeLogo: require('../../assets/images/sl1.png'),
          isActive: false,
        },
        {
          label: '窗口审核中',
          logo: require('../../assets/images/sh.png'),
          activeLogo: require('../../assets/images/sh1.png'),
          isActive: false,
        },
        {
          label: '登簿',
          logo: require('../../assets/images/db.png'),
          activeLogo: require('../../assets/images/db1.png'),
          isActive: false,
        },
        {
          label: '缮证',
          logo: require('../../assets/images/sz.png'),
          activeLogo: require('../../assets/images/sz1.png'),
          isActive: false,
        },
        // {
        //   label: '缴费',
        //   logo: require('../../assets/images/jf.png'),
        //   activeLogo: require('../../assets/images/jf1.png'),
        //   isActive: false
        // },
        {
          label: '发证',
          logo: require('../../assets/images/fz.png'),
          activeLogo: require('../../assets/images/fz1.png'),
          isActive: false,
        },
        {
          label: '归档',
          logo: require('../../assets/images/sl.png'),
          activeLogo: require('../../assets/images/sl1.png'),
          isActive: false,
        },
        // {
        //   label: '办结',
        //   logo: require('../../assets/images/bw.png'),
        //   activeLogo: require('../../assets/images/bw1.png'),
        //   isActive: false
        // }
      ],
      // djjg: '',
      // slsj: ''
    };
  },
  computed: {
    ...mapGetters(['selectedOrganizationsCode', 'selectedOrganizationsTitle']),
  },
  mounted() {
    // this.formData.djjg = this.selectedOrganizationsCode;
    // this.formData.qydm = this.selectedOrganizationsCode; // 获取区域码qydm
    this.formData.qqjgmc = this.selectedOrganizationsTitle;
  },
  methods: {
    getLogoUrl({ logo, activeLogo, isActive }) {
      return isActive ? activeLogo : logo;
    },
    activeProgressImg(isActive) {
      return isActive ? require('../../assets/images/jdt1.png') : require('../../assets/images/jdt.png');
    },
    // 重置进度状态
    resetActive() {
      this.progressList.forEach((item) => {
        item.isActive = false;
      });
    },
    submit() {
      //百度统计
      if (window.CONFIG.isOpenBDTJ === true) _hmt.push(['_trackEvent', '我要查询', '进度查询', '进度查询']);
      this.resetActive();
      // this.formData.qydm = this.selectedOrganizationsCode;
      // 获取区域码qydm
      this.formData.qydm = JSON.parse(localStorage.getItem('finalAreaDataLocal'))[0].code;
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log('formData--->>>>', this.formData);
          if (this.formData.qydm) {
            getYwzt(this.formData)
              .then((res) => {
                if (res.data && res.success && JSON.stringify(res.data) !== '{}') {
                  const step = Array.isArray(res.data) ? res.data[0].step : res.data.step;
                  this.processActiveSwitch(step);
                } else {
                  this.$message.error('未查到该笔业务进度信息');
                }
              })
              .catch((err) => {
                this.$message.error(err);
              });
          } else {
            this.$message('请选择服务大厅');
          }
          // if (this.formData.qydm) {
          //   getYwzWeb(this.formData).then((res) => {
          //     if (res.data && res.success) {
          //       this.processActiveSwitch(res.data.step);
          //       this.djjg = res.data.djjg;
          //       this.slsj = res.data.slsj;
          //     }
          //   });
          // } else {
          //   this.$message('请选择服务大厅!');
          // }
        }
      });
    },
    processActiveSwitch(res) {
      switch (res) {
        case 'NETAPPLYING':
        case '网上申请中':
          this.processActive(0);
          break;
        case 'VERIFICATION':
        case '认证中':
          this.processActive(0);
          break;
        case 'VERIFYSUCCESS':
        case '认证完成':
          this.processActive(0);
          break;
        case 'NETCHECKING':
        case '网上审核中':
          this.processActive(1);
          break;
        case 'NETPASSED':
        case '网上审核通过':
          this.processActive(2);
          break;
        case 'ACCEPTANCE':
        case '受理':
        case '受理中':
        case '窗口受理':
          this.processActive(3);
          break;
        case 'CHECKING':
        case '审核':
        case '审核中':
          this.processActive(4);
          break;
        case 'REGISTERING':
        case '登簿':
        case '登簿中':
          this.processActive(5);
          break;
        case 'CERTIFICATING':
        case '缮证':
        case '缮证中':
          this.processActive(6);
          break;
        // case 'PAYING':
        // case '缴费':
        // case '缴费中':
        //   this.processActive(4);
        //   break;
        case 'AWARD':
        case '发证':
        case '发证中':
          this.processActive(7);
          break;
        case 'GD':
        case '归档':
        case '归档中':
        case '已完成':
          this.processActive(8);
          break;
        // case 'COMPLETE':
        // case '办结':
        // case '办结中':
        //   this.processActive(7);
        //   break;
        case 'NETNOTPASS':
        case '网上审核未通过':
          this.processActive(-1);
          this.$message.warning('网上审核未通过');
          break;
        default:
          this.processActive(-1);
          this.$message.warning(res);
          break;
      }
    },
    processActive: function (index) {
      for (let i = 0; i < this.progressList.length; i++) {
        if (i <= index) {
          this.progressList[i].isActive = true;
        } else {
          this.progressList[i].isActive = false;
        }
      }
    },
    enlarge() {
      window.open('../../assets/images/wycx/example1.png');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/public.scss';
@import '~@/styles/common-variables.scss';

.progress-query {
  .form-content {
    .tip {
      /*height: 40px;*/
      margin-bottom: 60px;
      border-bottom: 1px solid #e7e7e7;

      span {
        display: block;
        height: 40px;
        width: 100px;
        line-height: 40px;
        text-align: center;
        color: #101010;
        font-weight: bold;
        border-bottom: 2px solid #ff5b24;
      }
    }

    .examples-link {
      height: 34px;
    }
  }

  .jdcx-center {
    margin-top: 50px;
    margin-bottom: 40px;

    label {
      font-size: 16px;
      float: left;
      line-height: 80px;
      margin-right: 30px;
      font-weight: 700;
    }

    .jdcx-item {
      /*width: 130px;*/
      height: 80px;

      .jd-jt {
        width: 50px;
        line-height: 60px;
        text-align: center;
      }

      .jd-img {
        text-align: center;

        .img-status {
          margin-bottom: $spacing-base;
        }

        img {
          width: 60px;
          height: 60px;
          background-color: #f3f3f3;
          border-radius: 50%;
        }
      }
    }

    .label-active {
      color: #ff5b24;
    }
  }

  p {
    margin-top: $spacing-base;
    font-weight: bold;
    padding-left: 160px;
  }
  .example-list {
    width: 100%;
    top: 20px;
    .example-img {
      width: 60%;
      margin: 20px 0;
    }
  }
}
</style>
